<template>
  <div ref="root" class="sp-container" :class="modifierClasses">
    <slot class="slot" />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";
const props = defineProps({
  flex: {
    type: [Boolean, String],
    default: false,
  },

  direction: {
    type: String,
    default: "row",
    validator: (value) => ["row", "column"].includes(value),
  },

  directionSm: {
    type: String,
    default: "column",
    validator: (value) => ["row", "column"].includes(value),
  },
});
const root = ref(null);
const { isSmScreen } = useBreakpoints(root);
const modifierClasses = computed(() => ({
  "--flex": props.flex,
  "--is-sm-screen": isSmScreen,
}));
</script>

<style>
:host {
  display: block;
  width: 100%;
}
</style>

<style scoped lang="scss">
.sp-container {
  &.--flex {
    --direction: v-bind(direction);

    .slot {
      display: flex;
      flex-direction: var(--direction);
      width: 100%;
    }

    &.--is-sm-screen {
      --direction: v-bind(directionSm);
    }
  }
}
</style>
