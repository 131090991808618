import { ref } from "vue";

const formElement = ref();

export function useWaitlistStore() {
  function isValid() {
    formElement.value.reportValidity();
    return formElement.value.checkValidity();
  }

  async function submitForm() {
    const url = formElement.value.action;

    const formData = new FormData(formElement.value);

    formData.append("waitlist", "true");

    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      return data.confirmation_url;
    }

    throw new Error("An error occurred while submitting the form.");
  }

  function reset() {
    formElement.value?.reset();
  }

  return {
    setFormElement: (element) => (formElement.value = element),

    get formElement() {
      return formElement.value;
    },

    isValid,
    submitForm,
    reset,
  };
}
