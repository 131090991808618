<template>
  <sp-modal
    ref="root"
    width="var(--waitlist-modal-width)"
    persistent
    closeable
    no-bottom-sheet
    no-mobile-content-padding
    :open="open"
    :modal-title="modalTitle"
    :scrollable-content="false"
    :class="classModifiers"
    @close="handleModalClose"
    @keydown.enter="submitForm"
  >
    <sp-button ref="activator" slot="activator" class="activator" block color="default">
      {{ activatorText }}
    </sp-button>

    <sp-alert v-if="error" slot="alert" type="error" variant="elevated" closeable @close="error = null">
      {{ error }}
    </sp-alert>

    <BasePanel :preamble="preamble" :info="info">
      <div ref="slotContainer" class="form-slot">
        <slot />
      </div>
    </BasePanel>

    <sp-container slot="actions" flex>
      <sp-spacer></sp-spacer>
      <sp-button color="primary" :loading="loading" @click="submitForm">Join waitlist</sp-button>
    </sp-container>
  </sp-modal>
</template>

<script setup>
import { useEventListener } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";
import { useExpose } from "../../composables/expose";
import { createCtaActivator } from "../../utils/cta";
import BasePanel from "./components/BasePanel.ce.vue";
import { useWaitlistStore } from "./store/waitlist";

const props = defineProps({
  /**
   * The title of the modal
   *
   * @type {String}
   * @default "Waitlist"
   */
  modalTitle: {
    type: String,
    default: "Waitlist",
  },

  /**
   * The text for the activator button
   *
   * @type {String}
   * @default "Get Started!"
   */
  activatorText: {
    type: String,
    default: "Join Waitlist",
  },
  /**
   * The text for the CTA activator button
   *
   * @type {String}
   * @default undefined
   */
  mobileCtaActivatorText: {
    type: String,
    default: undefined,
  },

  /**
   * If set, the modal will show a preamble at the top of the form panel
   *
   * @type {String}
   * @default undefined
   */
  preamble: {
    type: String,
    default: undefined,
  },

  /**
   * If set, the modal will show an info message at the top of the form panel
   *
   * @type {String}
   * @default undefined
   */
  info: {
    type: String,
    default: undefined,
  },
});

const waitlistStore = useWaitlistStore();

const open = ref(false);
const loading = ref(false);
const error = ref(null);

const root = ref(null);
const activator = ref(null);

const slotContainer = ref(null);
const formSlot = ref(null);
const formElement = ref(null);

const { exposeProperties } = useExpose(root);

/**
 * Returns the CTA activator element
 * This is used to expose the activator button to the parent component, so it can be used to open the modal from
 * outside the component.
 */
function getCtaActivator() {
  const ctaActivator = createCtaActivator(props.mobileCtaActivatorText ?? props.activatorText);
  useEventListener(ctaActivator, "click", () => (open.value = true));
  return ctaActivator;
}

exposeProperties({
  ctaActivator: {
    get: getCtaActivator,
  },
});

watch(slotContainer, (value) => {
  formSlot.value = value?.querySelector("slot");
});

/**
 * In order to access the form element, we need to wait for the slot to be populated.
 * We listen for the slotchange event, extract the form element, and store it in the form element ref.
 */
useEventListener(formSlot, "slotchange", () => {
  const slotContent = formSlot.value?.assignedElements?.()?.at(0);
  formElement.value = slotContent?.querySelector("form");
  removeUnwantedFormElements();
  waitlistStore.setFormElement(formElement.value);
});

function removeUnwantedFormElements() {
  formElement.value?.querySelector("[type=submit]")?.remove();
  formElement.value?.querySelector("[data-behaviour=preview-form]")?.remove();
}

function handleModalClose() {
  reset();
}

async function submitForm() {
  if (!waitlistStore.isValid()) {
    return;
  }

  try {
    loading.value = true;
    const confirmationUrl = await waitlistStore.submitForm();
    open.value = false;
    nextTick(() => (document.location.href = confirmationUrl));
  } catch (err) {
    console.error(err.message);
    error.value = err?.message ?? "An error occurred. Please try again later.";
    loading.value = false;
  }
}

function reset() {
  waitlistStore.reset();
  loading.value = false;
  error.value = null;
}

const { isSmScreen } = useBreakpoints();
const classModifiers = computed(() => ({
  "--bp-sm": isSmScreen.value,
}));
</script>

<style>
:host {
  --header-background: var(--sp-sys-layout-hero-before-content-background, #eee);
  --header-row-gap: var(--sp-ref-spacing-12);
  --card-padding-inline: var(--sp-comp-card-padding-inline, 1.5rem);
  --offset-inline: calc(var(--card-padding-inline) * -1);
  --card-padding-block: var(--sp-comp-card-padding-block, 0.5rem);
  --offset-block: calc(var(--card-padding-block) * -1);
  --dialog-height: 94vh;
  --dialog-min-height: 44rem;
  --dialog-max-height: 58rem;

  display: block;
}

sp-modal {
  --waitlist-modal-width: 40rem;

  &.--bp-sm {
    --waitlist-modal-width: calc(100dvw - var(--sp-ref-spacing-8));
  }
}

sp-heading[level="2"] {
  margin-top: calc(var(--sp-ref-spacing-8) * 3.625);
  margin-bottom: calc(var(--sp-ref-spacing-8) * 1.625);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.activator {
  --bg-color: var(--sp-comp-waitlist-activator-bg-color, var(--sp-sys-color-warning));
  --hover-bg-color: var(--sp-comp-waitlist-activator-hover-bg-color, var(--bg-color));
  --text-color: var(--sp-comp-waitlist-activator-text-color, var(--sp-sys-color-on-surface));
  --hover-text-color: var(--sp-comp-waitlist-activator-hover-text-color, var(--text-color));
}

.form-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: var(--sp-ref-spacing-12);
}
.actions {
  display: flex;
  width: 100%;
}
</style>
