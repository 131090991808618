<template>
  <svg ref="root" viewBox="0 0 120 24" part="base" class="sp-animated-ellipsis" :class="classModifiers">
    <circle v-for="i in numberOfCircles" :key="i" :r="radius" :cx="`${calculateCX(i)}`" :cy="radius">
      <animate
        attributeName="opacity"
        :dur="duration"
        values="0.2;1;0.2"
        keyTimes="0; 0.2; 1"
        repeatCount="indefinite"
        :begin="`0.${i}`"
      />
    </circle>
  </svg>
</template>

<script setup>
import { computed, ref } from "vue";
import { useColorToCssProperty } from "../../composables/color";
const root = ref(null);

const props = defineProps({
  size: {
    type: String,
    default: "normal",
    validator: (value) => ["x-small", "small", "normal", "large", "x-large"].includes(value),
  },
  color: {
    type: String,
    default: undefined,
  },
});

useColorToCssProperty(props, root);

const duration = "1s";
const numberOfCircles = 3;

const radius = 12;
const distance = radius * 4;

function calculateCX(i) {
  const distanceMultiplier = i - 1;
  return radius + distance * distanceMultiplier;
}

const classModifiers = computed(() => [`--size-${props.size}`]);
</script>

<style lang="scss">
:host {
  display: flex;
  --color: var(--sp-comp-animated-ellipsis-color, currentColor);
  --height: var(--sp-comp-animated-ellipsis-height, var(--sp-ref-spacing-4, 0.5rem));
}
</style>

<style scoped lang="scss">
svg {
  $sizes: (
    x-small: var(--sp-ref-spacing-2, 0.25rem),
    small: var(--sp-ref-spacing-3, 0.375rem),
    normal: var(--sp-ref-spacing-4, 0.5rem),
    large: var(--sp-ref-spacing-6, 0.75rem),
    x-large: var(--sp-ref-spacing-8, 1rem),
  );

  height: var(--height);
  width: auto;

  @each $name, $height in $sizes {
    &.--size-#{$name} {
      --height: #{$height};
    }
  }
}

circle {
  fill: var(--color);
  opacity: 0;
}
</style>
