<template>
  <div class="sp-list-subheader">
    <slot> {{ title }}</slot>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
});

const title = computed(() => props.title?.trim() ?? "");

/**
 * The display property of the subheader.
 *
 * The subheader is part of the list of items and is used for various calculations.
 * We need to render the subheader element to keep the list items aligned, but we don't want to display it if there is no title.
 *
 * @type {ComputedRef<String>}
 */
const display = computed(() => (title.value.length > 0 ? "flex" : "none"));
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-list-subheader {
  display: v-bind(display);
  align-items: center;
  color: var(--sp-comp-list-subheader-color, var(--sp-sys-color-text, #000));
  font-family: var(--sp-comp-list-subheader-font-family, var(--sp-sys-typescale-small-font-family, sans-serif));
  font-size: var(--sp-comp-list-subheader-font-size, var(--sp-sys-typescale-small-font-size, 0.813rem));
  font-weight: var(--sp-comp-list-subheader-font-weight, var(--sp-sys-typescale-small-font-weight, 400));
  padding-block: var(--sp-comp-list-subheader-padding-block, var(--sp-comp-list-item-padding-block, 0));
  min-height: var(--sp-comp-list-subheader-min-height, var(--sp-comp-list-item-min-height, 2.75rem));
  padding-inline: var(--sp-comp-list-subheader-padding-inline, var(--sp-comp-list-item-padding-inline, 0.75rem));
  background-color: var(--sp-comp-list-subheader-background-color, var(--sp-sys-color-primary-container, #ddd));
}
</style>
