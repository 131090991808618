<template>
  <div class="sp-tooltip">
    <div class="activator" @click="toggleActive" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
      <slot />
    </div>
    <sp-popup
      :active="isActive && isEnabled"
      :pointer="true"
      location="top"
      :pointer-position="pointerPosition"
      :horizontal-align="horizontalAlign"
      part="popup"
    >
      <div class="content">
        <slot name="content">{{ content }}</slot>
      </div>
    </sp-popup>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { toBoolean } from "../../utils/props";

const props = defineProps({
  content: {
    type: String,
    default: undefined,
  },
  active: {
    type: [Boolean, String],
    default: false,
  },
  activatorCursor: {
    type: String,
    default: "pointer",
  },
  enabled: {
    type: [Boolean, String],
    default: true,
  },
  pointerPosition: {
    type: String,
    default: "center",
    validator: (value) => ["left", "center", "right"].includes(value),
  },
  horizontalAlign: {
    type: String,
    default: "center",
    validator: (value) => ["left", "center", "right"].includes(value),
  },
});

const isActive = ref(toBoolean(props.active));
const isEnabled = ref(toBoolean(props.enabled));
watch(
  () => props.active,
  (value) => (isActive.value = toBoolean(value)),
  () => props.enabled,
  (value) => (isEnabled.value = toBoolean(value)),
);

function toggleActive() {
  isActive.value = !isActive.value;
}

function onMouseenter() {
  isActive.value = true;
}

function onMouseleave() {
  isActive.value = false;
}
</script>

<style>
:host {
  display: inline-block;
  position: relative;
}
</style>

<style lang="scss" scoped>
.activator {
  cursor: v-bind(activatorCursor);
}

.content ::slotted(*) {
  font-size: var(--sp-comp-tooltip-font-size, 0.875rem);
  line-height: var(--sp-comp-tooltip-line-height, 1.5);
  display: var(--sp-ce-slot-display, inline-flex) !important;
}

sp-popup {
  --sp-comp-popup-min-height: 0;
  --sp-comp-popup-background-color: var(--sp-comp-tooltip-background-color, #fff);
  --sp-comp-popup-border-width: 1px;
  --sp-comp-popup-border-color: var(--sp-comp-tooltip-border-color, #e5e5e5);
  --sp-comp-popup-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  --sp-comp-popup-pointer-size: 0.75rem;
  --sp-comp-popup-content-padding: var(--sp-comp-tooltip-content-padding, 1rem 1.25rem);
}
</style>
