<template>
  <sp-modal
    ref="root"
    width="var(--checkout-modal-width)"
    persistent
    closeable
    no-bottom-sheet
    no-mobile-content-padding
    :open="open"
    :modal-title="modalTitle"
    :is-hidden-temporarily="isHiddenTemporarily"
    :scrollable-content="false"
    :class="classModifiers"
    @update-open="handleModalUpdateOpen"
    @close="handleModalClose"
  >
    <sp-button ref="activator" slot="activator" class="activator" block color="default">
      {{ activatorText }}
    </sp-button>

    <sp-alert v-if="error" slot="alert" type="error" variant="elevated" closeable @close="error = null">
      {{ error }}
    </sp-alert>

    <template v-if="purchaseStore.hasPurchaseToken">
      <ExpiryAlert slot="content-overlay" @close="closeModal" />

      <sp-stepper
        ref="stepperElement"
        no-actions
        item-value="key"
        :items.prop="stepperItems"
        :completed.prop="completedSteps"
        :selected.prop="selectedStep"
        scrollable
        @update-selected="updateSelectedStep"
      >
        <sp-stepper-window-item
          v-for="({ component, key, preamble }, index) in stepperItems"
          :slot="`item-${index + 1}`"
          :key="key"
        >
          <component
            :is="component"
            ref="componentRefs"
            :resource="resource"
            :components="components"
            :preamble="preamble"
            class="stepper-item-content-component"
            @goto="gotoStep"
          >
            <slot :name="key" />
          </component>
        </sp-stepper-window-item>
      </sp-stepper>

      <sp-stepper-actions
        slot="actions"
        :disabled="disabledActions"
        :loading="loading"
        :next-text="nextText"
        prev-text="Go Back"
        @prev="prev"
        @next="next"
      />
    </template>
    <sp-card-content-overlay slot="content-overlay" :visible="!purchaseStore.hasPurchaseToken">
      <sp-animated-ellipsis size="large" color="primary" />
    </sp-card-content-overlay>
  </sp-modal>
</template>

<script setup>
import { useEventListener, watchImmediate } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";
import { useExpose } from "../../composables/expose";
import { createCtaActivator } from "../../utils/cta";
import { toBoolean, toJSON } from "../../utils/props";
import ExpiryAlert from "./components/ExpiryAlert.ce.vue";
import { useCheckout } from "./composables/checkout";
import { useState } from "./composables/state";
import { usePurchaseStore } from "./store/purchase";

const purchaseStore = usePurchaseStore();

const { error, isHiddenTemporarily } = useState();

const props = defineProps({
  /**
   * The title of the modal
   *
   * @type {String}
   * @default "Checkout"
   */
  modalTitle: {
    type: String,
    default: "Checkout",
  },

  /**
   * The text for the activator button
   *
   * @type {String}
   * @default "Get Started!"
   */
  activatorText: {
    type: String,
    default: "Get Started!",
  },
  /**
   * The text for the CTA activator button
   *
   * @type {String}
   * @default undefined
   */
  mobileCtaActivatorText: {
    type: String,
    default: undefined,
  },
  /**
   * Whether the form has a payment step
   *
   * @type {Boolean|String}
   * @default false
   */
  payment: {
    type: [Boolean, String],
    default: false,
  },

  /**
   * Whether the form has a review step
   *
   * @type {Boolean|String}
   * @default false
   */
  review: {
    type: [Boolean, String],
    default: false,
  },

  /**
   * The type of checkout
   *
   * @type {String}
   * @values ["event"]
   * @default undefined
   */
  type: {
    type: String,
    default: undefined,
    validator: (value) => ["event"].includes(value),
  },

  /**
   * The resource to checkout
   * This is required for the event type, and optional for other types
   *
   * @type {Object}
   * @default undefined
   */
  resource: {
    type: String,
    default: undefined,
  },

  /**
   * The URL to add the resource to the cart
   *
   * @type {String}
   * @default undefined
   */
  addToCartUrl: {
    type: String,
    default: undefined,
  },
  /**
   * Whether the modal should be activated on mount
   * This is useful for when the modal should be opened automatically, and also run all logic bound to the activator
   *
   * @type {Boolean}
   * @default false
   */
  open: {
    type: [Boolean, String],
    default: false,
  },
});

const {
  stepperItems,
  components,
  stepper,
  hasReview,
  selectedStep,
  completedSteps,
  StepperKeys,
  completeStep,
  resetStep,
  resetSteps,
  gotoStep,
} = useCheckout(props);

const open = ref(toBoolean(props.open));
const redirecting = ref(false);
const loading = ref(false);

const root = ref(null);
const activator = ref(null);
const componentRefs = ref([]);

// The FormPanel component is the only component that has a prev and next method
// TODO: @sleistner - with Vue 3.5 and above, we can use the `useTemplateRef` composable to get the ref of the component.
const formViewRef = computed(() => componentRefs.value?.find(({ prev, next }) => prev && next));

const { exposeProperties } = useExpose(root);

/**
 * Returns the CTA activator element
 * This is used to expose the activator button to the parent component, so it can be used to open the modal from
 * outside the component.
 */
function getCtaActivator() {
  const ctaActivator = createCtaActivator(props.mobileCtaActivatorText ?? props.activatorText);
  useEventListener(ctaActivator, "click", () => (open.value = true));
  return ctaActivator;
}

exposeProperties({
  ctaActivator: {
    get: getCtaActivator,
  },
});

const stepperElement = ref(null);

function updateSelectedStep({ detail }) {
  const [step] = detail;
  selectedStep.value = step;
}

const resource = ref(toJSON(props.resource));

watch(
  () => props.resource,
  (value) => (resource.value = toJSON(value)),
);

const nextText = computed(() => {
  if (selectedStep.value === stepperItems.value.length - 1) {
    return stepper.value.finalStepText;
  }
  return stepper.value.nextText;
});

const disabledActions = computed(() => {
  if (loading.value ?? error.value) {
    return true;
  }

  if (selectedStep.value === 0) {
    return "prev";
  }

  return false;
});

const currentStep = computed(() => stepperItems.value[selectedStep.value]);
const currentStepKey = computed(() => currentStep.value?.key);

function prev() {
  if (currentStepKey.value === StepperKeys.FORM && formViewRef.value?.prev()) {
    return;
  }

  resetStep(currentStepKey.value);

  // As we are going back we also need to reset the previous step which is going to be the current step
  // Otherwise the step will be marked as complete even though the user might have changed input values
  const previousStepKey = stepperItems.value[selectedStep.value - 1]?.key;
  resetStep(previousStepKey);

  stepperElement.value.prev();
}

async function next() {
  loading.value = true;
  error.value = null;

  await transitionFrom(currentStepKey.value);
  if (redirecting.value) {
    return;
  }
  loading.value = false;
}

function getTransition(key) {
  const transitions = {
    [StepperKeys.CART]: transitionFromCart,
    [StepperKeys.FORM]: transitionFromForm,
    [StepperKeys.PAYMENT]: transitionFromPayment,
    [StepperKeys.REVIEW]: transitionFromReview,
  };

  return transitions[key];
}

/**
 * Transitions from the current step to the next step.
 *
 * The transition is determined by the key of the current step.
 * If the transition is successful, the current step is marked as complete.
 * Otherwise, the transition is halted and the current step is not marked as complete.
 *
 * @param key {String} - The key of the current step
 * @see {@link StepperKeys}
 */
async function transitionFrom(key) {
  const transition = getTransition(key);
  const result = await transition();

  if (result !== false) {
    completeStep(key);
  }
}

async function transitionFromCart() {
  stepperElement.value.next();
}

/**
 * Transitions from the start step
 *
 * We validate the form and halt the transition if the form is invalid.
 * Next we submit the form and validate the result.
 * If the form has a payment step, we store the payment details and transition to the payment step.
 * If the form does not have a payment step, we transition to the review step.
 *
 * @returns {Boolean} Whether the transition was successful, false if the form is invalid or the submission failed
 */
async function transitionFromForm() {
  if (formViewRef.value?.next()) {
    return false;
  }

  if (!purchaseStore.isValid()) {
    return false;
  }

  const success = await purchaseStore.submitForm();
  if (!success) {
    error.value = "Error submitting form";
    return false;
  }

  stepperElement.value.next();
}

/**
 * Transitions from the payment step
 *
 * We validate the payment form and halt the transition if the form is invalid.
 * If a review step is required, we transition to that step.
 * If no review step is required, we confirm the payment and redirect the user to the return URL.
 */
async function transitionFromPayment() {
  error.value = null;

  const { error: validationError } = await purchaseStore.validatePayment();

  if (validationError) {
    console.error("Error validating payment", validationError);
    error.value = validationError.message;
    return false;
  }

  if (hasReview.value) {
    stepperElement.value.next();
  } else {
    return await purchaseOrRedirect();
  }
}

/**
 * Transitions from the review step
 *
 * If payment is required, the payment will be confirmed and the user will be redirected to the return URL.
 * If no payment is required, the user will be redirected to ???.
 */
async function transitionFromReview() {
  return await purchaseOrRedirect();
}

async function purchaseOrRedirect() {
  if (purchaseStore.paymentRequired.value) {
    return await confirmPayment();
  }
  redirecting.value = true;
  window.location.href = purchaseStore.returnUrl.value;
}

async function confirmPayment() {
  startChallengeFrameWatcher();
  const result = await purchaseStore.confirmPayment();
  stopChallengeFrameWatcher();
  isHiddenTemporarily.value = false;

  if (result.error) {
    error.value = result.error.message;
    return false;
  }
}

let challengeFrameWatcher = null;

function startChallengeFrameWatcher() {
  const watchInterval = 500;
  const maxRetries = 500;

  let retries = 0;

  const watchHandler = () => {
    const frames = Array.from(document.getElementsByTagName("iframe"));
    const challengeFrame = frames.find(({ src }) => src.includes("-challenge"));

    if (challengeFrame) {
      isHiddenTemporarily.value = true;
    }

    if (retries >= maxRetries || challengeFrame) {
      stopChallengeFrameWatcher();
    }

    retries++;
  };

  challengeFrameWatcher = window.setInterval(watchHandler, watchInterval);
}

function stopChallengeFrameWatcher() {
  if (challengeFrameWatcher) {
    window.clearInterval(challengeFrameWatcher);
    challengeFrameWatcher = null;
  }
}

function closeModal() {
  open.value = false;
}

function handleModalClose() {
  if (!isHiddenTemporarily.value) {
    reset();
  }
}

function handleModalUpdateOpen({ detail }) {
  const [value] = detail;
  open.value = value;
}

watchImmediate(open, (value) => {
  if (!value) {
    return;
  }
  nextTick(() => purchaseStore.addToCart(props.addToCartUrl));
});

function reset() {
  purchaseStore.reset();
  error.value = null;
  loading.value = false;
  redirecting.value = false;
  selectedStep.value = 0;
  resetSteps();
}

const { isSmScreen } = useBreakpoints();
const classModifiers = computed(() => ({
  "--bp-sm": isSmScreen.value,
}));
</script>

<style>
:host {
  --header-background: var(--sp-sys-layout-hero-before-content-background, #eee);
  --header-row-gap: var(--sp-ref-spacing-12);
  --card-padding-inline: var(--sp-comp-card-padding-inline, 1.5rem);
  --offset-inline: calc(var(--card-padding-inline) * -1);
  --card-padding-block: var(--sp-comp-card-padding-block, 0.5rem);
  --offset-block: calc(var(--card-padding-block) * -1);
  --dialog-height: 94vh;
  --dialog-min-height: 44rem;
  --dialog-max-height: 58rem;
  --sp-comp-stepper-header-background: var(--header-background);
  --sp-comp-stepper-header-margin: var(--offset-block) -1.5rem 0 -1.5rem;
  --sp-comp-stepper-header-padding: var(--header-row-gap) var(--card-padding-inline);
  --sp-comp-stepper-header-gap: 0;

  display: block;
}

sp-modal {
  --checkout-modal-width: 40rem;

  &.--bp-sm {
    --checkout-modal-width: calc(100dvw - var(--sp-ref-spacing-8));
  }
}

sp-stepper-actions {
  --sp-comp-stepper-actions-gap: var(--sp-ref-spacing-8);
  margin-top: var(--sp-ref-spacing-4);
  margin-bottom: var(--sp-ref-spacing-4);
}

sp-stepper-actions::part(prev-action),
sp-stepper-actions::part(next-action) {
  width: 100%;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.stepper-item-content-component {
  height: 100%;
  overflow-y: auto;
}

.activator {
  --bg-color: var(--sp-comp-checkout-activator-bg-color, var(--sp-sys-color-warning));
  --hover-bg-color: var(--sp-comp-checkout-activator-hover-bg-color, var(--bg-color));
  --text-color: var(--sp-comp-checkout-activator-text-color, var(--sp-sys-color-on-surface));
  --hover-text-color: var(--sp-comp-checkout-activator-hover-text-color, var(--text-color));
}
</style>
