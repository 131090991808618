<template>
  <div ref="slotContainer">
    <slot />
  </div>
</template>

<script setup>
/**
 * FieldSelect
 *
 * A custom Vue component for managing and toggling between multiple form fields within a slot.
 * This component determines which field is visible, hides inactive fields, and disables their inputs.
 * It uses a "Next" action to cycle through the available fields.
 *
 * ### Usage
 * ```html
 * <sp-field-select>
 *   <div>
 *     <label>Upload</label>
 *     <input type="text" />
 *     <span sp-field-select-next>Pick from list</span>
 *   </div>
 *   <div>
 *     <label>Pick from List</label>
 *     <textarea></textarea>
 *     <span sp-field-select-next>Upload</span>
 *   </div>
 * </sp-field-select>
 * ```
 *
 * ### Slots
 * - The component accepts any number of child elements inside its slot. Each child element
 *   represents a selectable "field." The child elements should have:
 *   - An optional `default` attribute to mark it as the default field.
 *   - Any valid form controls (e.g., `<input>`, `<textarea>`).
 *
 * ### Behavior
 * - Automatically selects and shows the first field marked with the `default` attribute, or the first field if none are marked as default.
 * - Cycles through the fields when an element with `sp-field-select-next` is clicked. Placing these elements in each field allows the text to hint what the next field will be.
 * - Shows only the selected field, hiding others.
 * - Enables inputs in the visible field and disables inputs in hidden fields.
 *
 * ### Example
 * ```html
 * <sp-field-select>
 *   <div>
 *     <label>First Field</label>
 *     <input type="text" />
 *   </div>
 *   <div default>
 *     <label>Second Field</label>
 *     <input type="email" />
 *   </div>
 *   <button sp-field-select-next>Next</button>
 * </field-select>
 * ```
 */

import { useEventListener } from "@vueuse/core";
import { computed, ref, watch } from "vue";

const selectedIndex = ref(null);
const slot = ref(null);
const slotContainer = ref(null);

const fields = computed(() => slot.value?.assignedElements?.());
const nextIndex = computed(() => (selectedIndex.value + 1) % fields.value.length);
const nextButtons = computed(() =>
  fields.value?.flatMap((field) => Array.from(field.querySelectorAll("sp-field-select-next"))),
);

const selectedField = computed(() => {
  let selectedElement;

  // Prefer the field at the selectedIndex
  if (selectedIndex.value !== null && selectedIndex.value !== undefined) {
    selectedElement = fields.value[selectedIndex.value];
  }

  // If none, then try for the field marked as default
  if (!selectedElement) {
    selectedElement = fields.value.filter((element) => element.getAttribute("default"))[0];
  }

  // Else, fall back to showing the first field
  if (!selectedElement) {
    selectedElement = fields.value[0];
  }

  return selectedElement;
});

// BEHAVIOUR

watch(slotContainer, (value) => {
  slot.value = value.querySelector("slot");
});

watch(slotContainer, updateSelected); // Initialize once rendered
watch(selectedIndex, updateSelected);
useEventListener(slot, "slotchange", updateSelected);

watch(nextButtons, (buttons) => {
  buttons.forEach((button) => useEventListener(button, "click", handleClick));
});

// EVENT HANDLERS

function handleClick(event) {
  selectedIndex.value = nextIndex.value;
  event.preventDefault(); // If the element was a link, don't follow it.
}

// HELPER FUNCTIONS

function updateSelected() {
  // Hide or show fields depending on which is selected
  fields.value.forEach((element) => {
    if (element === selectedField.value) {
      showElement(element);
      enableInputs(element);
    } else {
      hideElement(element);
      disableInputs(element);
    }
  });
}

function showElement(element) {
  element.style.display = "block";
}

function hideElement(element) {
  element.style.display = "none";
}

function disableInputs(container) {
  container.querySelectorAll("*:where(input, select, textarea):not([disabled])").forEach((element) => {
    element.disabled = true;
    element.dataset.disabledBySpFieldSelect = true;
  });
}

function enableInputs(container) {
  container.querySelectorAll("[data-disabled-by-sp-field-select='true']").forEach((element) => {
    element.disabled = false;
    delete element.dataset.disabledBySpFieldSelect;
  });
}
</script>

<style></style>
