import { trimToUndefined } from "../../../utils/props";

export function getEligibilityElements(formElement) {
  return Array.from(formElement.querySelectorAll("input[type=radio][data-eligibility]"));
}

export function getIneligibleElements(formElement) {
  return getEligibilityElements(formElement).filter(({ dataset }) => dataset.eligibility === "ineligible");
}

export function getCheckedIneligibleElements(formElement) {
  return getIneligibleElements(formElement).filter(({ checked }) => checked);
}

export function prepareEligibilityValidation(formElement) {
  restoreEligibilityValidation(formElement);

  const checkedIneligibleElements = getCheckedIneligibleElements(formElement);

  checkedIneligibleElements.forEach((element) => {
    const message = trimToUndefined(element.dataset.eligibilityMessage) ?? "Please select a different option";
    element.setCustomValidity(message);
  });
}

export function restoreEligibilityValidation(formElement) {
  const ineligibleElements = getIneligibleElements(formElement);

  ineligibleElements.forEach((element) => element.setCustomValidity(""));
}
